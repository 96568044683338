import { ICatalogProduct } from "@royalcanin-fr-loyalty/ui-kit";
import { format } from "date-fns";

export const getProductPromoTippedText = (product: ICatalogProduct) => {
  if (product.promoStartDate && product.promoEndDate) {
    return `Promotion valable du ${format(
      new Date(product.promoStartDate),
      "dd.MM.yyyy",
    )} au ${format(
      new Date(product.promoEndDate),
      "dd.MM.yyyy",
    )} à la commande de ${product.label}.`;
  }

  return;
};
