import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Locale } from "../../../../../__generated__/globalTypes";
import {
  PurchaseShippingAddress,
  PurchaseShippingAddressVariables,
} from "./__generated__/PurchaseShippingAddress";

export const PurchaseShippingAddressFragment = gql`
  fragment PurchaseShippingAddressFragment on Address {
    isImmediateDelivery
    wholesalerName
    name
    street
    street2
    zip
    city
    country
    number
  }
`;

const PurchaseShippingAddressQuery = gql`
  query PurchaseShippingAddress(
    $token: String!
    $productId: String!
    $locale: Locale!
  ) {
    purchaseShippingAddress(
      token: $token
      productId: $productId
      locale: $locale
    ) {
      isImmediateDelivery
      wholesalerName
      name
      street
      street2
      zip
      city
      country
      number
    }
  }
`;

export const usePurchaseShippingAddress = ({
  productId,
}: {
  productId?: string;
}) => {
  const token = useUserToken();
  const res = useQuery<
    PurchaseShippingAddress,
    PurchaseShippingAddressVariables
  >(PurchaseShippingAddressQuery, {
    skip: !token || !productId,
    variables: {
      token,
      locale: Locale.fr,
      productId: productId || "",
    },
  });

  const purchaseShippingAddress = res.data && res.data.purchaseShippingAddress;

  return {
    ...res,
    data: purchaseShippingAddress,
  };
};
