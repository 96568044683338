import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useApolloClient, useQuery } from "react-apollo";
import { Locale } from "../../../../../__generated__/globalTypes";
import { ProductFragment } from "./Fragments";
import { Product, ProductVariables } from "./__generated__/Product";

const ProductQuery = gql`
  query Product($token: String!, $locale: Locale!, $id: String!) {
    product(token: $token, locale: $locale, id: $id) {
      ...ProductFragment
    }
  }
  ${ProductFragment}
`;

export const useFetchProduct = () => {
  const client = useApolloClient();
  const token = useUserToken();
  return (id: string) =>
    client.query<Product, ProductVariables>({
      query: ProductQuery,
      variables: {
        id,
        token,
        locale: Locale.fr,
      },
    });
};

export const useProduct = (id: string) => {
  const token = useUserToken();
  const res = useQuery<Product, ProductVariables>(ProductQuery, {
    skip: !token || !id,
    variables: {
      id,
      token,
      locale: Locale.fr,
    },
  });

  const product = res.data && res.data.product;

  return {
    ...res,
    data: product || undefined,
  };
};
