import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useMutation } from "react-apollo-hooks";
import { Locale } from "../../../../../__generated__/globalTypes";
import { useCatalogPurchasesQuery } from "./useCatalogPurchases";
import { useLoyaltyRetrieveBalanceQuery } from "./useLoyaltyRetrieveBalance";
import {
  CatalogPurchaseCreate,
  CatalogPurchaseCreateVariables,
} from "./__generated__/CatalogPurchaseCreate";

const CatalogPurchaseCreateMutation = gql`
  mutation CatalogPurchaseCreate(
    $token: String!
    $locale: Locale!
    $productId: String!
    $quantity: Float!
    $clientReference: String
  ) {
    catalogPurchaseCreate(
      token: $token
      locale: $locale
      productId: $productId
      quantity: $quantity
      clientReference: $clientReference
    ) {
      result {
        id
      }
      error {
        code
        message
      }
    }
  }
`;

export const useCatalogPurchaseCreate = () => {
  const token = useUserToken();
  const [catalogPurchaseCreate, catalogPurchase] = useMutation<
    CatalogPurchaseCreate,
    CatalogPurchaseCreateVariables
  >(CatalogPurchaseCreateMutation);
  const refetchQueryPurchases = useCatalogPurchasesQuery();
  const refetchQueryRetrieveBalance = useLoyaltyRetrieveBalanceQuery();

  return {
    ...catalogPurchase,
    catalogPurchaseCreate: async (
      quantity: number,
      productId?: string,
      clientReference?: string,
    ) => {
      const { data } = await catalogPurchaseCreate({
        refetchQueries: [refetchQueryPurchases, refetchQueryRetrieveBalance],
        awaitRefetchQueries: true,
        variables: {
          token,
          locale: Locale.fr,
          productId: productId || "",
          quantity,
          clientReference,
        },
      });

      return data && data.catalogPurchaseCreate;
    },
  };
};
