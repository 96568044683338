import { Wrapper } from "@royalcanin-fr-loyalty/ui-kit";
import React from "react";
import { Layout } from "../components/Layout";
import { withPage } from "../lib/withPage";

const Error500 = ({ error }: { error: any }) => (
  <Layout>
    <Wrapper>
      <h1>Error</h1>
      <pre>{error && error.message}</pre>
    </Wrapper>
  </Layout>
);

export default withPage(Error500);
