import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Locale } from "../../../../../__generated__/globalTypes";
import { PurchaseShippingAddressFragment } from "./usePurchaseShippingAddress";
import {
  CatalogPurchases,
  CatalogPurchasesVariables,
} from "./__generated__/CatalogPurchases";

export const CatalogPurchasesQuery = gql`
  query CatalogPurchases(
    $token: String!
    $locale: Locale!
    $page: Float
    $limit: Float
  ) {
    catalogPurchases(
      token: $token
      locale: $locale
      page: $page
      limit: $limit
    ) {
      pageInfo {
        page
        count
        limit
        hasNextPage
      }
      result {
        id
        date
        partner {
          extId
          name
        }
        shippingAddress {
          ...PurchaseShippingAddressFragment
        }
        price
        initialPrice
        quantity
        status
        productData {
          name
          reference
          weight
          image

          thumbImage: cloudinaryImage (rawTransformation: "t_fid_thumb") {
            src
            webp: src (format: "webp")
          }
        }
        type
      }
    }
  }

  ${PurchaseShippingAddressFragment}
`;

export const useCatalogPurchasesQuery = ({
  page = 1,
}: { page?: number } = {}) => {
  const token = useUserToken();
  return {
    query: CatalogPurchasesQuery,
    variables: {
      token,
      locale: Locale.fr,
      limit: 10,
      page,
    },
    skip: !token,
  };
};

export const useCatalogPurchases = ({
  page,
}: {
  page?: number;
} = {}) => {
  const { variables, query, skip } = useCatalogPurchasesQuery({ page });
  const { loading: initialLoading, ...res } = useQuery<
    CatalogPurchases,
    CatalogPurchasesVariables
  >(query, {
    skip,
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const pageInfo = (res.data &&
    res.data.catalogPurchases &&
    res.data.catalogPurchases.pageInfo) || {
    page: 1,
    hasNextPage: false,
  };

  const catalogPurchases =
    (res.data &&
      res.data.catalogPurchases &&
      res.data.catalogPurchases.result) ||
    [];

  const loading =
    initialLoading ||
    (!initialLoading && !catalogPurchases.length && !res.networkStatus);

  return {
    ...res,
    catalogPurchases,
    loading,
    pageInfo,
    onLoadMore: async () => {
      if (!pageInfo.hasNextPage || loading) {
        return Promise.resolve();
      }

      return res.fetchMore({
        variables: {
          page: pageInfo.page + 1,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          return {
            catalogPurchases: {
              ...prev.catalogPurchases,
              result: [
                ...prev.catalogPurchases.result,
                ...fetchMoreResult.catalogPurchases.result,
              ],
              pageInfo: fetchMoreResult.catalogPurchases.pageInfo,
            },
          };
        },
      });
    },
  };
};
