import {
  Form,
  ICatalogProduct,
  IShippingDetailProps,
  Loader,
  Modal,
  ModalHeader,
  ModalOrderNoPoint,
  ModalOrderProduct,
  ModalOrderSubmit,
  ModalOrderSuccess,
  ModalOrderSummary,
  useInputProps,
} from "@royalcanin-fr-loyalty/ui-kit";
import { FORM_ERROR } from "final-form";
import React, { useState } from "react";
import { number, object, string } from "yup";
import { useCatalogPurchaseCreate } from "../lib/hooks/useCatalogPurchaseCreate";
import { useLoyaltyRetrieveBalance } from "../lib/hooks/useLoyaltyRetrieveBalance";
import { usePurchaseShippingAddress } from "../lib/hooks/usePurchaseShippingAddress";
import { getProductPromoTippedText } from "../lib/productUtils";

interface IBaseprops {
  product?: ICatalogProduct;
  quantity?: number;
  onClose: () => void;
}

const hasEnoughPoints = (
  product: ICatalogProduct,
  quantity: number,
  currentPoints: number,
) =>
  currentPoints >=
  (product.promo
    ? (product.promoPrice || 0) * quantity
    : (product.price || 0) * quantity);

export const CatalogProductPurchaseModal = ({
  open,
  onClose,
  ...rest
}: {
  open: boolean;
} & IBaseprops) => (
  <Modal open={open} onClose={onClose}>
    <ModalHeader onClose={onClose}>Confirmation de commande</ModalHeader>
    <ModalCatalogPurchaseContent onClose={onClose} {...rest} />
  </Modal>
);

const ModalCatalogPurchaseContent = ({
  product,
  quantity,
  onClose,
}: IBaseprops) => {
  const [success, setSuccess] = useState(false);
  const { catalogPurchaseCreate } = useCatalogPurchaseCreate();
  const {
    data: currentPoints,
    loading: currentPointsLoading,
  } = useLoyaltyRetrieveBalance();
  const {
    data: shippingAddress,
    loading: shippingAddressLoading,
  } = usePurchaseShippingAddress({
    productId: product ? product.id : undefined,
  });

  if (!product) {
    return <Loader />;
  }

  if (success) {
    return (
      <>
        <ModalOrderSuccess points={currentPoints} />
        <ModalOrderSubmit
          ordersLink="/orders"
          catalogLink="/catalog"
          success={success}
        />
      </>
    );
  }

  if (
    !currentPointsLoading &&
    !hasEnoughPoints(product, quantity || 1, currentPoints)
  ) {
    return <ModalOrderNoPoint onClick={onClose} />;
  }

  const wholesalerWithClientReferenceRequired = product.wholesalers.find(
    (wholesaler) => wholesaler.isClientReferenceRequired,
  );
  const isClientReferenceRequired = !!wholesalerWithClientReferenceRequired;
  const wholesalerClientReferenceDescription =
    wholesalerWithClientReferenceRequired?.clientReferenceDescription;

  return (
    <Form
      initialValues={{
        quantity: quantity || 1,
        clientReference: "",
      }}
      schema={object().shape({
        quantity: number().min(1),
        clientReference: isClientReferenceRequired
          ? string().required()
          : string(),
      })}
      validate={(values) => {
        if (isClientReferenceRequired && !values.clientReference) {
          return { clientReference: "Ce champ est requis" };
        }

        return !success &&
          !currentPointsLoading &&
          !hasEnoughPoints(product, values.quantity, currentPoints)
          ? { [FORM_ERROR]: "no point" }
          : {};
      }}
      onSubmit={async (values) => {
        try {
          await catalogPurchaseCreate(
            values.quantity,
            product.id,
            values.clientReference,
          );
          setSuccess(true);
        } catch (err) {
          setSuccess(false);
        }
      }}
      render={({ handleSubmit, error, values, submitting, valid }) => (
        <form onSubmit={handleSubmit}>
          <CatalogPurchaseForm
            shippingAddress={shippingAddress}
            shippingAddressLoading={shippingAddressLoading}
            product={product}
            quantity={values.quantity || 1}
            orderButtonDisabled={!valid || submitting || currentPointsLoading}
            isError={!!error}
            isClientReferenceRequired={isClientReferenceRequired}
            clientReferenceDescription={wholesalerClientReferenceDescription || undefined}
          />
        </form>
      )}
    />
  );
};

const CatalogPurchaseForm = ({
  product,
  quantity,
  shippingAddress,
  shippingAddressLoading,
  orderButtonDisabled,
  isError,
  isClientReferenceRequired,
  clientReferenceDescription,
}: {
  product: ICatalogProduct;
  quantity: number;
  shippingAddress?: IShippingDetailProps;
  shippingAddressLoading: boolean;
  orderButtonDisabled: boolean;
  isError: boolean;
  isClientReferenceRequired?: boolean;
  clientReferenceDescription?: string;
}) => (
  <>
    <ModalOrderProduct
      product={product}
      quantityInputProps={useInputProps({ name: "quantity" })}
    />
    <ModalOrderSummary
      shippingAddress={shippingAddress}
      shippingAddressLoading={shippingAddressLoading}
      tippedContent={getProductPromoTippedText(product)}
      product={product}
      quantity={quantity}
      clientReferenceInputProps={
        isClientReferenceRequired
          ? {
              ...useInputProps({ name: "clientReference" }),
              description: clientReferenceDescription,
            }
          : undefined
      }
    />
    <ModalOrderSubmit
      orderButtonDisabled={orderButtonDisabled}
      error={isError}
    />
  </>
);
