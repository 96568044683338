import emojiRegex from "emoji-regex";
import { useEffect, useState } from "react";

export const filterBoolean = <T>(n?: T): n is T => Boolean(n);

let _isWebpSupported: undefined | boolean;
export const isWebpSupported = () => {
  if (typeof _isWebpSupported !== "undefined") {
    return _isWebpSupported;
  }

  _isWebpSupported =
    typeof document === "undefined"
      ? false
      : document
          .createElement("canvas")
          .toDataURL("image/webp")
          .indexOf("data:image/webp") === 0;

  return _isWebpSupported;
};

export const useHasMounted = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
};

export const cleanEmoji = (text: string) => {
  const rgx = emojiRegex();
  return text.replace(rgx, "");
};
